import 'scss/component/cookie-banner/cookie-banner.scss';
import { useState, useEffect, useRef } from 'react';
import Button from 'components/ui/Button';
import { getCookieValue } from 'helper/cookie';
import Icon from "./Icon";

const CookieBanner = () => {
    const cookiesInitial = {
        chosen: getCookieValue('chosen'),
        analytics: getCookieValue('analytics'),
        marketing: getCookieValue('marketing')
    }
    const initialPane = 'initial';
    const optionsPane = 'options';
    const closedPane = 'closed';
    const [currentPane, setCurrentPane] = useState((cookiesInitial.chosen ? closedPane : initialPane));
    const [reload, setReload] = useState(false);
    const analyticsInput = useRef(null);
    const marketingInput = useRef(null);

    const [cookies, setCookies] = useState(cookiesInitial);
    const [selectedOptions, setSelectedOptions] = useState(cookiesInitial);

    useEffect(() => {
        setSelectedOptions(cookies);
    }, [currentPane, cookies]);

    useEffect(() => {
        const date = new Date();
        date.setMonth(date.getMonth() + 6)
        document.cookie = 'cookie_options=' + JSON.stringify(cookies) + '; expires=' + date.toUTCString() + '; path=/; secure; samesite=strict';
        if (cookies.chosen) {
            setCurrentPane(closedPane);
        }
    }, [cookies]);

    useEffect(() => {
        if (reload) {
            document.location.reload();
        }
    }, [reload]);

    const declineAll = () => {
        setCookies({ chosen: true, analytics: false, marketing: false });
        setReload(true);
    };

    const acceptAll = () => {
        setCookies({ chosen: true, analytics: true, marketing: true });
        setReload(true);
    };
    const saveCookies = (event) => {
        setCookies({ chosen: true, analytics: analyticsInput.current.checked, marketing: marketingInput.current.checked });
        setReload(true);
    };

    const optionChanged = (event, field) => {
        if (field === 'analytics') {
            setSelectedOptions({
                chosen: selectedOptions.chosen,
                analytics: analyticsInput.current.checked,
                marketing: selectedOptions.marketing,
            });
        }
        if (field === 'marketing') {
            setSelectedOptions({
                chosen: selectedOptions.chosen,
                analytics: selectedOptions.analytics,
                marketing: marketingInput.current.checked,
            });
        }
    };

    return (
        <div className={'cookie-banner' + (currentPane === closedPane ? ' cookie-banner--closed' : '')}>
            <div className={ 'cookie-banner__panel cookie-banner__panel--initial' + (currentPane === initialPane ? '' : ' cookie-banner__panel--hidden') }>
                <h3>Usage of cookies</h3>
                <p>We use necessary cookies to make our site work. We'd also like to set analytics cookies that help us make improvements by measuring how you use the site. You can manage your <button onClick={ () => setCurrentPane(optionsPane) }>cookie settings here.</button></p>
                <Button colour="purple" onClick={ declineAll }>Reject all</Button>
                <Button colour="purple" onClick={ acceptAll }>Accept all</Button>
            </div>
            <div className={ 'cookie-banner__panel cookie-banner__panel--options' + (currentPane === optionsPane ? '' : ' cookie-banner__panel--hidden') }>
                <h3>Manage Individual Cookie Preferences</h3>
                <div className="cookie-banner__options-option form__field-checkbox">
                    <div className="cookie-banner__options-option-input">
                        <label className="cookie-banner__options-option form__field-checkbox-title" htmlFor="cookies_necessary">
                            Necessary Cookies
                            <span className="cookie-banner__options-option-input-wrapper">
                                <input id="cookies_necessary" type="checkbox" readOnly="" disabled="disabled" value="1" checked="checked"/>
                                <span className="cookie-banner__options-option-input-dot"></span>
                                <span className="cookie-banner__options-option-input-text"></span>
                            </span>
                        </label>
                    </div>
                    <p>Essential for the functionality of the site.</p>
                </div>

                <div className="cookie-banner__options-option form__field-checkbox">
                    <div className="cookie-banner__options-option-input">
                        <label className="cookie-banner__options-option form__field-checkbox-title" htmlFor="cookies_analytics">
                            Analytics Cookies
                            <span className="cookie-banner__options-option-input-wrapper">
                                <input ref={ analyticsInput } id="cookies_analytics" type="checkbox" checked={ selectedOptions.analytics === null ? true : selectedOptions.analytics } value="1" onChange={(e) => optionChanged(e, 'analytics')} />
                                <span className="cookie-banner__options-option-input-dot"></span>
                                <span className="cookie-banner__options-option-input-text"></span>
                            </span>
                        </label>
                    </div>
                    <p>Optional Cookies that help us to improve the site.</p>
                </div>

                <div className="cookie-banner__options-option form__field-checkbox">
                    <div className="cookie-banner__options-option-input">
                        <label className="cookie-banner__options-option form__field-checkbox-title" htmlFor="cookies_marketing">
                            Tracking
                            <span className="cookie-banner__options-option-input-wrapper">
                                <input ref={ marketingInput } id="cookies_marketing" type="checkbox" checked={ selectedOptions.marketing === null ? true : selectedOptions.marketing } value="1"  onChange={(e) => optionChanged(e, 'marketing')}/>
                                <span className="cookie-banner__options-option-input-dot"></span>
                                <span className="cookie-banner__options-option-input-text"></span>
                            </span>
                        </label>
                    </div>
                    <p>Optional Cookies that help us to improve the site.</p>
                </div>
                <Button colour="purple" onClick={ saveCookies }>Save and close</Button>
                <Button colour="purple" simple onClick={ () => setCurrentPane(initialPane) }>Go back</Button>
            </div>
            { currentPane === closedPane && <button className="cookie-banner__icon" onClick={ () => setCurrentPane(initialPane) }><Icon icon="CookieIcon" /></button> }
        </div>
    );
}

export default CookieBanner;
