import ReactGA4 from 'react-ga4';
import { api } from 'helper/api/client';
import permission from 'helper/permission';

const log = {
    customer: {
        accountNumber: '',
        type: '',
        sector: ''
    },
    lastPage: null,
    shouldLog() {
        const requiresLogin = !['/', '/auth', '/forgotten-password', '/reset-password', '/limited'].includes(this.getUrl());
        return (requiresLogin && this.customer.accountNumber) || !requiresLogin;
    },
    setCustomer(customer) {
        this.customer = { accountNumber: customer.accountNumber, type: customer.type, sector: customer.sector };
        const customerDiv = document.getElementById('customer');
        customerDiv.dataset.accountNumber = customer.accountNumber;
        customerDiv.dataset.type = customer.type;
        customerDiv.dataset.sector = customer.sector;
        customerDiv.dataset.hasTelemetry = permission.check('hasTelemetry', false) ? true : false;

        if (process.env.REACT_APP_GA4_ID) {
            let ga4Options = {
                user_id: customer.accountNumber,
                send_to: process.env.REACT_APP_GA4_ID
            };
            if (process.env.REACT_APP_GA4_DEBUG === 'true') {
                ga4Options.debug_mode = true;
                console.log('GA4 config', process.env.REACT_APP_GA4_ID, ga4Options)
            }
            ReactGA4.gtag('config', process.env.REACT_APP_GA4_ID, ga4Options);
            ReactGA4.gtag('set', 'user_properties', {
                type: customer.type,
                sector: customer.sector,
                platform: 'portal',
                telemetry: !!permission.check('hasTelemetry', false),
            });
        }
    },
    getParams() {
        let userAgentBrands = navigator.userAgentData?.brands;
        return {
            url: this.getUrl(),
            userAgent: navigator.userAgent,
            userAgentBrands: userAgentBrands ? userAgentBrands : null,
            ...this.customer
        }
    },
    getUrl() {
        return window.location.pathname + window.location.search;
    },
    page() {
        if (this.shouldLog()) {
            if (this.lastPage === null || this.lastPage !== this.getUrl()) {
                if (process.env.REACT_APP_GA4_ID) {
                    // Removed due to double logging
                    //ReactGA4.send({ hitType: 'pageview', page: this.getUrl() });
                }
                api.post('log', { logType: 'page', ...this.getParams() }).catch(error => {
                    api.error(error);
                });
                this.lastPage = this.getUrl();
            }
        }
    },
    action(category, action, ga4Event, extraParams = {}) {
        if (process.env.REACT_APP_GA4_ID) {
            let eventName = ga4Event;
            if (eventName !== 'search') {
                eventName = 'portal_' + eventName;
            }
            ReactGA4.event(eventName, {
                event_action: action,
                event_category: category,
                ...{...extraParams, send_to: process.env.REACT_APP_GA4_ID }
            });
        }
        api.post('log', { logType: 'action', ...this.getParams(), category: category, action: action }).catch(error => {
            api.error(error);
        });
    },
    debug(category, action, extraParams = {}) {
        api.post('log', { logType: 'debug', ...this.getParams(), category: category, action: action, ...extraParams }).catch(error => {
            api.error(error);
        });
    }
};

export default log;